import React from 'react';
import SEO from '../../components/seo';
// import PropTypes from 'prop-types';

const index = () => (
  <SEO meta={[
    { 'http-equiv': 'refresh', content: '0;url=/' },
  ]}
  />
);

index.propTypes = {};
index.defaultProps = {};

export default index;
